// @ts-ignore
import VOverlay from 'vuetify/lib/components/VOverlay'
// @ts-ignore
import VDataIterator from 'vuetify/lib/components/VDataIterator'
// @ts-ignore
import VSlider from 'vuetify/lib/components/VSlider'
import Vue from 'vue'
import ui from './plugins/ui'

const findAncestor = (el: HTMLElement, sel: string) => {
  while ((el = el.parentElement!) && !((el.matches || (el as any).matchesSelector).call(el, sel)));
  return el
}

const VSliderOverwrite = VSlider as any
VSliderOverwrite.options.mounted = VSliderOverwrite.options.mounted.filter((m: Function) => !m.toString().includes('this.app = document.querySelector'))
VSliderOverwrite.options.mounted.push(function (this: any) {
  this.app = findAncestor(this.$el, '[data-app]')
})

const VOverlayOverwrite = VOverlay as any
VOverlayOverwrite.extendOptions.props.color.default = ui.framework.theme.dark ? '#2a2b2d' : '#ffffff'

const VDataFooterOverwrite = VDataIterator.$_vuetify_subcomponents.VDataFooter
// 999999 represents "All"
VDataFooterOverwrite.extendOptions.props.itemsPerPageOptions.default = () => ([5, 10, 20, 999999])
VDataFooterOverwrite.extendOptions.methods.genDataItemsPerPageOption = function (option: any) {
  return {
    text: option === 999999 ? this.$vuetify.lang.t(this.itemsPerPageAllText) : String(option),
    value: option
  }
}

const ConfirmDialogOverwrite = Vue.component('ConfirmDialog') as any
ConfirmDialogOverwrite.extendOptions.props.backdropColor.default = '#7C7782'
ConfirmDialogOverwrite.extendOptions.props.backdropOpacity.default = 0.46
